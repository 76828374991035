import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { addRegistration } from "./api"
import * as css from "./style.module.css"

const Register = () => {
  const [showSuccess, setShowSuccess] = useState(false)
  const [responseError, setResponseError] = useState()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm()

  const onSubmit = data => {
    console.log({data})
    if (!data.phone && !data.email) {
      setResponseError("Заполните одно из указанных полей")
      return
    }
    addRegistration(data).then(() => {
      setShowSuccess(true)
      setTimeout(() => {
        setShowSuccess(false)
      }, 10000)
      reset()
      setResponseError(undefined)
    }).catch((e)=>setResponseError("Ошибка. Повторите попытку позже"))
  }

  const SuccessMessage = () => <div className={css.registrationPane}>Благодарим за регистрацию</div>

  const RegForm = () => (
    <div className={css.registrationPane} id="registration">
      <h2 className={css.header}>Регистрация</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={css.verticalForm}>
          <label>Имя (Фамилия)</label>
          <input {...register("name")} className={css.field} />

          <label>Електронная почта</label>
          <input {...register("email")} className={css.field} />

          <label>Телефон</label>
          <input
            {...register("phone")}
            className={css.field}
          />

          {responseError && <span>{responseError}</span>}

          <button type="submit" className={css.submit}>
            Зарегистрироваться
          </button>
        </div>
      </form>
    </div>
  )

  if (showSuccess) return <SuccessMessage />
  return <RegForm />
}

export default Register
