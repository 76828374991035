import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import * as css from "./style.module.css"
import Register from "../Register"


const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "20210531-2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <p>
        <h3>Шаманский интенсив с Женей Жариковой /Лиссой Квятковской/ </h3>

        <h4 className={css.closeLines}>с 03 по 10 июля 2021 год</h4>
        <h4 className={css.closeLines}>Каневское море, с. Хоцки (Киевская обл.)</h4>
        <h4>ОТВОРИТЕ ДВЕРЬ В СВОЮ ЛУЧШУЮ ЖИЗНЬ</h4>
        <Img fluid={data.file.childImageSharp.fluid} alt="Header" />
      </p>

      <p style={{marginBottom:"10px"}}>
        <div className={css.question}>Всякая перемена прокладывает путь другим переменам</div>
        <div className={css.question}>Хотите перемен?</div>
        <div className={css.question}>Хотите изменить свою жизнь?</div>
        <div className={css.question}>Хотите изменить свою жизнь быстро?</div>
      </p>

      <h4 style={{marginTop:"0px"}}>Приглашаем вас на шаманский кэмп на природе «Щит силы» 03.07 — 10.07 с Женей Жариковой /Лиссой Квятковской/, где вы запустите ваши личные перемены с пользой и удовольствием</h4>   

      <div>Вы сделаете шаманский щит силы, он будет для вас активатором, защитником, союзником и помощником для ваших перемен к лучшей жизни</div>

      <p>
        <h4>Если вы хотите:</h4>
        <ul>
          <li>создать новые отношения</li>
          <li>создать новые отношения</li>
          <li>улучшить отношения с родными и близкими</li>
          <li>восстановить здоровье и исцелиться от болезней</li>
          <li>зарабатывать и получать достаточное для вас количество денег</li>
          <li>путешествовать по миру</li>
          <li>знакомиться с большим количеством людей</li>
          <li>познавать и присваивать новое</li>
          <li>приобретать новых друзей, партнеров по бизнесу, создавать крепкие связи</li>
          <li>научиться ставить цели и достигать их в свое удовольствие</li>
          <li>быть в контакте со своими желаниями, мечтами</li>
          <li>уметь осуществлять их легко, с удивлением и восторгом,</li>
          <li>и если вы хотите воплотить это в жизнь, вам сюда:</li>
        </ul>
        <div>Шаманский интенсив «Щит силы», эко отдых на берегу Каневского моря, вблизи села Хоцки (Киевская область), 03.07. — 10.07., где каждый участник найдет дверь в свою новую лучшую жизнь</div>
      </p>

      <a href="#registration" className={css.registrationButton}>Зарегистрироваться</a>

      <p>
        <h4>Практики:</h4>
        <ul>
          <li>авторский метод перезапись травматических ситуаций прошлого Re.Solution</li>
          <li>шаманское НЛП</li>
          <li>изготовление Щита силы</li>
          <li>шаманские путешествия</li>
          <li>шаманские расстановки</li>
          <li>практики работы с телом и телесными зажимами</li>
          <li>работа с ресурсами, архетипическими энергиями и большими фигурами</li>
          <li>работа с ПТСР и шоковой травмой по методу Somatic experiencing /автор Питер Левин/</li>
          <li>эпистемологическая метафора /автор Дэвид Гроув/</li>
          <li>системные и семейные расстановки</li>
          <li>системное моделирование /автор Александр Зелинский/</li>
          <li>трансформация реальности /автор Александр Череп/</li>
          <li>северо-, южноамериканский, тувинский, сибирский и гавайский шаманизм</li>
          <li>практики с голосом, танцевально-двигательная терапия</li>
          <li>Альфа-гравити /опционально/</li>
        </ul>
      </p>


      <h6>В мире нет ничего постоянного, кроме перемен, какие будут перемены — зависит от вас</h6>
      <h6>Перемены, которые произойдут на интенсиве, останутся с вами навсегда</h6>
      <h6>10 июля VIP-ритуал «Путешествие по возвращению души»</h6>

      <a href="#registration" className={css.registrationButton}>Регистрация</a>

      <section>
        <h4>Ведущая Женя Жарикова /Лисса Квятковская/:</h4>
        <ul>
          <li>Re.Solution /авторский метод перезаписи травматических - ситуаций прошлого/</li>
          <li>Сертифицированный НЛП-мастер</li>
          <li>Сертифицированный специалист по работе с ПТСР и шоковой травмой по методу Питера Левина Somatic experience</li>
          <li>Сертифицированный системный дизайнер, Системное моделирование /метод Александра Зелинского/</li>
          <li>Трансформация реальности /метод Александра Черепа/</li>
          <li>Эпистемологическая метафора</li>
          <li>Voice dialog, Диалог с голосами</li>
          <li>Шаманские расстановки</li>
          <li>Системные/семейные расстановки по Хеллингеру, семейная мандала</li>
          <li>Трансперсональная психология</li>
          <li>Холотропное дыхание, ребефинг</li>
          <li>Помощь в домашних/водных родах (доула)</li>
          <li>Холодинамика</li>
          <li>Тувинское горловое пение как целительный инструмент</li>
          <li>Танцевально-двигательная терапия</li>
          <li>Танатотерапия</li>
          <li>Работа со сновидениями</li>
          <li>Работа с архетипами</li>
          <li>Балансировка ауры</li>
          <li>Фототерапия /соединение фотографии и психотерапии</li>
          <li>Элементы канистерапии — работа в паре с собакой Джокером /именной сертификат NLPs dog:)/  </li>
        </ul>
      </section>

      <p>
        <div>Художник, дизайнер, фотограф, копирайтер, сценарист, рекламист /креативный директор рекламного агентства/</div>
        <div>Опыт работы 20+ лет</div>
      </p>

      <p>
        <h5>Отзывы</h5>
        <a href="https://www.youtube.com/watch?v=RvNgp79-_DE" target="_blank">Отзыв на кемп "Шаманский щит" 29-31 августа 2014 года от Татьяны Богомол</a><br/>
        <a href="https://www.youtube.com/watch?v=rJttfZjultU" target="_blank">Отзыв на кемп "Шаманский Щит" 28.09.2014 от Лады Олейник</a>
      </p>

      <p>
        <div>Проживание в собственных палатках</div>
        <div>До места на <a href="https://childcamp.com.ua/ru/camp/onmap/detskiy-lager-slavutich?fbclid=IwAR0-XiUjSkq9rCpYkhHzHoNFffNKtFNcr-p6lELFUE8da5NvcOT-Zvkp1mA" target="_blank">КАРТЕ</a> добираетесь самостоятельно</div>
      </p>

      <div>Цены доступные, программа эко-отдыха насыщенная, можно с детьми</div>

      <p>
        <h5>Стоимость участия:</h5>
        <div>До солнцестояния (21.06.) — 5555 грн</div>
        <div>До 28.06. — 6006 грн</div>
        <div>На интенсиве 7007 грн </div>
      </p>

      <p>
        <div>Стоимость ритуала «Возвращение души» — 2200 грн</div>
        <div>Возможно неполное прохождение интенсива, стоимость 1 дня — 2 200 грн</div>
        <div>В стоимость не входит питание, рассчитывается отдельно</div>
      </p>

      <p>
        <div>Подтверждением участия в интенсиве является предоплата в размере 1 500 грн</div>
      </p>

      <div>Детали: Катерина (067) 505 75115 </div>

      <Register />

    </>
  )
}

export default Header
