import fb from "firebase/app"
import getFirebase from "../../firebase"
// import { writeBatch, doc } from "firebase/firestore"; 

export function addRegistration(values) {
  // return Promise.reject("помилка")
  const firebase = getFirebase()
  const firestore = firebase.firestore
  const batch = firestore.batch()
  // const batch = writeBatch(firebase)

  const registrationsRef = firestore.collection("registrations").doc()
  batch.set(registrationsRef, {
    ...values,
    timestamp: fb.firestore.FieldValue.serverTimestamp(),
  })
  const stateRef = firestore.collection("state").doc("main")
  batch.set(stateRef, {
    timestamp: fb.firestore.FieldValue.serverTimestamp(),
  })
  return batch.commit().then(() => {
    console.info("Registration added")
  })
}

// export function addRegistration(values) {
//   const firebase = getFirebase()
//   return firebase.firestore
//     .collection("registrations")
//     .add({
//       ...values,
//       timestamp: fb.firestore.FieldValue.serverTimestamp(),
//     })
//     .then(docRef => {
//       console.info("Registration added with ID: %s", docRef.id)
//       return docRef
//     })
// }

